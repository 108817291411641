<template>
  <b-modal id="portalModal" :title="title" size="xl" @ok="ok" @hidden="reset" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="6">
          <label>Portalname:</label>
          <b-form-input v-model="displayname" placeholder="Portalname..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="6">
          <label>Adresse:</label>
          <b-form-input v-model="portalAddress" placeholder="Adresse..." trim/>
        </b-col>
        <b-col cols="3">
          <label>Anzahl: <b-icon-question-circle-fill id="tt-count" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="count" number type="number"/>
          <b-tooltip target="tt-count" triggers="hover">Anzahl an Lizenzen</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="note" rows="4"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit' && !data.inactive" variant="outline-danger" class="mr-auto" @click="togglePortal()">
        <b-spinner v-show="loadingActiveButton" small/>
        Portal inaktiv setzen
      </b-button>
      <b-button v-else-if="method === 'edit' && data.inactive" variant="outline-success" class="mr-auto"
                @click="togglePortal()">
        <b-spinner v-show="loadingActiveButton" small/>
        Portal aktiv setzen
      </b-button>
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";

export default {
  name: "PortalModal",
  props: ['method', 'data'],
  data() {
    return {
      id: null,
      displayname: '',
      count: 0,
      portalAddress: '',
      note: '',
      loadingOkButton: false,
      loadingActiveButton: false
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    data() {
      this.onShow()
    }
  },
  methods: {
    reset() {
      this.id = null
      this.displayname = ''
      this.count = 0
      this.portalAddress = ''
      this.note = ''
    },
    refresh() {
      this.$emit('refresh');
    },
    async ok() {
      switch (this.method) {
        case 'edit':
          await this.updateRow();
          break;
        case 'add':
          await this.addRow();
          break;
      }
      this.reset();
    },
    async onShow() {
      if (this.method === 'edit') {
        this.id = this.data.id
        this.displayname = this.data.displayname
        this.count = this.data.count
        this.portalAddress = this.data.portalAddress
        this.note = this.data.note
      }
      else {
        this.reset()
      }
    },
    async addRow() {
      this.loadingOkButton = true
      let data = {
        displayname: this.displayname,
        count: this.count,
        portal_address: this.portalAddress,
        note: this.note
      }

      await putRequest('portals/fg/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('portalModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateRow() {
      let data = {
        portal_id: this.id,
        displayname: this.displayname,
        count: this.count,
        portal_address: this.portalAddress,
        note: this.note
      }

      await putRequest('portals/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('portalModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async togglePortal() {
      this.loadingActiveButton = true
      await putRequest('portals/fg/inactive-toggle/' + this.data.id, null, this, 'Der Eintrag wurde erfolgreich umgesetzt.', 'Der Eintrag konnte nicht umgesetzt werden.')
          .then(() => {
            this.loadingActiveButton = false
            this.refresh()
            this.$bvModal.hide('portalModal')
          })
          .catch(() => {
            this.loadingActiveButton = false
          })

    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    iconVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Portal ändern'
      }
      return 'Portal hinzufügen'
    },
    disabledButton() {
      return !(this.displayname && this.count)
    },
    variantSuccess() {
      if(this.userThemeId === 2) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
